// import logo from "assets/images/brands/brand-main-sm.png";
// import sidebar from "assets/images/brands/Exinitic.png";
import logo from "assets/images/brands/InfinanceFx-logo.jpg";

export const clientName = "InfinanceFx";
export const developedBy = "InfinanceFx";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL ;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_URL;
export const companyName =  "InfinanceFx";
export const sidebarLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;
